import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledImageTestimonial = styled.div`
  .holder {
    margin-left: calc(var(--gutter) * -1);
    display: grid;
    grid-template-columns: 57.6041% 1fr;
    align-items: center;
    grid-gap: ${pxtorem(87)};
  }

  .image {
    &, ${StyledShowOnScroll}, ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .author {
    display: flex;
    align-items: center;
    margin-top: ${pxtorem(36)};

    &__avatar {
      --radius: 59px;
      width: var(--radius);
      height: var(--radius);
      border-radius: 100%;
      overflow: hidden;
      transform: translateZ(0);
      backface-visibility: hidden;
    }

    &__info {
      font: 400 ${pxtorem(16)}/120% var(--secondaryFont);
      text-transform: uppercase;
      margin-left: 18px;
    }

    &__name, &__role {
      display: block;
    }

    &__role {
      opacity: .5;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .text {
    text-align: center;
    font: 300 ${pxtorem(30)}/${pxtorem(42)} var(--primaryFont);
    max-width: 523px;
    p {
      margin: 0;
    }
  }

  .client {
    margin-bottom: ${pxtorem(36)};
  }

  @media (max-width: 768px) {
    .holder {
      grid-template-columns: 1fr;
      grid-gap: 56px;
      margin-right: calc(var(--gutter) * -1);
    }

    .content {
      padding: 0 var(--gutter);
    }

    .author {
      margin-bottom: 40px;
    }

    .client {
      margin-top: 40px;
    }
  }
`;