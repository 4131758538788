import styled from 'styled-components';
import { pxtorem, pxtovw} from '../../utils/Tools';

export const StyledCtaBanner = styled.div`
  padding: ${pxtorem(160)} 0;
  .box {
    padding: ${pxtorem(144)} ${pxtorem(75)};
    border-radius: 10px;
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-gap: ${pxtovw(87)};
    @media (min-width: 1920px) {
      grid-gap: ${pxtorem(87)};
    }
    align-items: center;
    
    @media (max-width: 1400px) {
      padding: ${pxtorem(100)} ${pxtorem(75)};
      grid-template-columns: 1fr 1fr;
    }
  }

  .title {
    font: 300 max(${pxtovw(80)}, 32px)/90% var(--primaryFont);
    @media (min-width: 1920px) {
      font: 300 80px/90% var(--primaryFont);
    }

    margin: 0;
  }

  .text {
    font: 400 ${pxtorem(16)}/150% var(--secondaryFont);
    margin-bottom: ${pxtorem(35)};
    max-width: 600px;
    p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    .box {
      padding: 70px var(--gutter);
      grid-template-columns: 1fr;
      grid-gap: 40px;
      text-align: center;
    }


    .text {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 768px) {
    padding: 80px 0;
  }
`;  