import { pxtorem } from "src/utils/Tools";
import styled from "styled-components";

export const StyledHomePage = styled.div`
  .video-controls {
    position: relative;
    display: flex;

    .video-info {
      position: absolute;
      bottom: 40px;
      left: 40px;

      button {
        border: none;
        background: none;
        margin-bottom: 12px;
      }
      p {
        font: 400 ${pxtorem(20)} / 1.2 var(--secondaryFont);
        margin: 0;
      }

      @media (max-width: 768px) {
        bottom: 16px;
        left: 16px;
      }
    }
  }

  .sizzle-reel {
    width: 100%;
  }
`
