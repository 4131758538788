import { Link } from 'gatsby';
import React, { useEffect, useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { paths } from '../../utils/paths';
import { PactContext } from '../PactContext';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledStoryItem } from './StoryItem.style';

export const StoryItem: React.FC<StoryItemProp> = ({ item, ratio = 'normal', roundImages = false }) => {
  //#region Hooks / Lifecycles
  const { language } = useContext(PactContext);

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { category, featuredImage, title, slug } = item;
  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  const linkHref = `${paths[language].prefix === '' ? '' : `/${paths[language].prefix}`}/${paths[language].stories}/${slug}`;
  return (
    <StyledStoryItem ratio={ratio} roundedImage={roundImages}>
      {featuredImage && (
        <ShowOnScroll>
          <div className="media">
            <Link to={linkHref}>
              <PactImage image={featuredImage} alt="" />
            </Link>
          </div>
        </ShowOnScroll>
      )}

      <ShowOnScroll>
        <div className="info">
          <span className="category">{category}</span>
          <div className="desc">
            <Link to={linkHref}>
              <ReactMarkdown>{title}</ReactMarkdown>
            </Link>
          </div>
        </div>
      </ShowOnScroll>
    </StyledStoryItem>
  )
  //#endregion
}

interface StoryItemProp {
  item: StoryItemData;
  ratio?: 'wide' | 'normal'; 
  roundImages?: boolean;
}

export interface StoryItemData {
  title?: string;
  weight: number;
  showInListing: boolean;
  category?: string;
  featuredImage: any;
  smallDescription: string;
  slug: string; 
  titleFR?: string;
}
