import React from 'react';
import { graphql } from 'gatsby';
import { CaseStudyItemData } from '../components/CaseStudyItem/CaseStudyItem';
import { HeroBannerData } from '../components/HeroBanner/HeroBanner';
import { getLocaleMdData } from '../utils/Tools';
import { FancyTextProp } from '../components/FancyText/FancyText';
import { ImageTestimonialProps } from '../components/ImageTestimonial/ImageTestimonial';
import { CtaBannerProps } from '../components/CtaBanner/CtaBanner';
import { Page } from '../components/Page';
import { HomePage } from '../components/HomePage/HomePage';
import { withLang } from '../utils/reactTools';
import { SEO, SEOProps } from '../components/SEO/SEO';

export const Home: React.FC<HomeProps> = ({ data, language }) => {
  
  const _data = getLocaleMdData(data);
  
  const {
    pageTheme,
    seo
  } = _data[language] || {};

  const globalSEO: SEOProps = (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title, description, ogImage } = (seo ? seo[0] : null) || {};

  return (
    <Page background={pageTheme || 'charcoal'} language={language} slugs={{ en: '/', fr: '/fr' }}>
      <SEO
        title={title ? title : globalSEO.title}
        description={description ? description : globalSEO.description}
        ogImage={ogImage ? ogImage : globalSEO.ogImage} />
      <HomePage data={data} />
    </Page>
  )
}
``
export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
        }
      }
    }

    markdownRemark(fileAbsolutePath: { regex: "/pages/home.md/" }) {
      fields {
        locales {
          en {
            pageTheme
            ...SeoFragmentEN
            ...heroBannerFragmentEN
            heroVideoUrl
            heroVideoTitle
            caseStudiesHeading
            caseStudies
            caseStudiesGridCtaLabel
            caseStudiesGridCtaUrl
            ...fancyTextFragmentEN
            ...imageTestimonialFragmentEN
            ...ctaBannerFragmentEN
            ...homeStoriesCarouselFragmentEN
          }
        }
      }
    }
    allCaseStudies: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "case-study" } } }
    ) {
      nodes {
        fields {
          locales {
            en {
              title
              client
              weight
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 2716
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }

    allStories: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "story" } } }
    ) {
      nodes {
        fields {
          locales {
            en {
              title
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 878 
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }
  }
`;

type HomeProps = LocalizedAllMarkdownDataRelation<HomeData, CaseStudyItemData> & PropsWithLang<HomeData>;

interface HomeData {
  pageTheme: ThemeBG;
  seo: SEOProps[];
  heroBanner: HeroBannerData[];
  heroVideoUrl: string;
  heroVideoTitle: string;
  caseStudiesHeading: string;
  caseStudies: string[];
  caseStudiesGridCtaLabel: string;
  caseStudiesGridCtaUrl: string;
  fancyText: FancyTextProp['data'];
  imageTestimonial: ImageTestimonialProps['data'];
  ctaBanner: CtaBannerProps['data'][];
  stories: {
    title: string;
  }[];
}

export default withLang(Home, 'en');
