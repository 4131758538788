import styled from 'styled-components';
import { pxtorem, pxtovw } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledFancyText = styled.div`
  padding: ${pxtorem(200)} 0 0;
  margin-bottom: ${pxtorem(200)};
  text-align: center;
  font: 300 max(${pxtovw(120)}, 44px)/90% var(--primaryFont);
  text-transform: uppercase;
  @media (min-width: 1920px) {
    font: 300 120px/90% var(--primaryFont);
  }

  .text, .image {
    display: inline-block;
    vertical-align: middle;
  }

  .image {
    --pillHeight: ${pxtovw(86)};
    --pillWidth: ${pxtovw(146)};
    @media (min-width: 1920px) {
      --pillHeight: 86px;
      --pillWidth: 146px;
    }
    background: var(--color);
    position: relative;
    width: var(--pillWidth);
    height: var(--pillHeight);
    border-radius: var(--pillHeight);
    overflow: hidden;
    vertical-align: baseline;
    transform: translateZ(0);
    backface-visibility: hidden;

    &--circle {
      --pillWidth: ${pxtovw(86)};
      @media (min-width: 1920px) {
        --pillWidth: 86px;
      }
    }

    ${StyledShowOnScroll} {
      transition: opacity 1s;
      opacity: 0;
      &.visible {
        opacity: 1;
      }
    }
    
    ${StyledPactImage} {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 88px 0 0;
    margin-bottom: 88px;
    /* .image {
      display: none;
    } */

    .image {
      --pillHeight: 0.75em;
      --pillWidth: 1.3em;
      &--circle {
        --pillWidth: 0.75em;
      }
    }
  }
`