import styled from 'styled-components';
import { pxtovw, pxtorem } from '../../utils/Tools';

export const StyledHomeCaseStudiesGrid = styled.div`
  padding-bottom: ${pxtorem(200)};

  .heading {
    text-align: center;
    margin-top: ${pxtorem(200)};
    margin-bottom: ${pxtorem(160)};
    font: 300 ${pxtorem(120)} / 90% var(--primaryFont);
    text-transform: uppercase;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${pxtovw(140)} ${pxtovw(160)};
    @media (min-width: 1920px) {
      grid-gap: 140px 160px;
    }
    margin-bottom: 113px;
  }
  
  .item {
    display: block;

    @media (min-width: 768px) {

      &--1 {
        grid-column: 1 / span 2;
        padding: 0 ${pxtovw(146)};
        @media (min-width: 1920px) {
          padding: 0 146px;
        }
      }

      &--2 {
        padding: 0 ${pxtovw(76)} 0 ${pxtovw(146)};
        @media (min-width: 1920px) {
          padding: 0 76px 0 146px;
        }
      }

      &--3 {
        padding: 0 ${pxtovw(76)} 0 0;
        transform: translateY(${pxtovw(169)});
        @media (min-width: 1920px) {
          transform: translateY(169px);
          padding: 0 76px 0 0;
        }
      }

      &--5 {
        padding: ${pxtovw(333)} ${pxtovw(76)} 0 0;
        @media (min-width: 1920px) {
          padding: 333px 76px 0 0;
        }
      }
    }
  }

  .ctaHolder {
    margin-top: ${pxtorem(113)};
    text-align: center;
  }

  @media (max-width: 768px) {
    .items {
      grid-template-columns: 1fr;
      grid-gap: 80px;
    }
  }
`