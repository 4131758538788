import React, { useMemo, useState } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledImageTestimonial } from './ImageTestimonial.style';
import { Swiper as SwiperJSX, SwiperSlide } from 'swiper/react';
import { Swiper, SwiperOptions } from 'swiper';

const DEFAULT_OPTIONS: SwiperOptions = {
  loop: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  slidesPerView: 'auto',
  spaceBetween: 20,
};

export const ImageTestimonial: React.FC<ImageTestimonialProps> = ({ data }) => {
  //#region Variables
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [images] = useState<ImageTestimonialProps['data'][0]['image'][]>(
    data.map(({ image }) => image)
  );
  const testimonials = useMemo(() => {
    return data.map((test, index) => {
      const {
        testimonial,
        author,
        authorRole,
        authorAvatar,
        clientLogo,
        clientName,
      } = test;

      const key = `${clientName}-${index}`;
      return (
        <React.Fragment key={key}>
          
          {clientLogo && (
            <ShowOnScroll>
              <div className="client">
                <PactImage image={clientLogo} alt={clientName} addMaxWidth />
              </div>
            </ShowOnScroll>
          )}


          <ShowOnScroll>
            <div className="text">
              <p>&ldquo;{testimonial}&rdquo;</p>
            </div>
          </ShowOnScroll>
          
          <ShowOnScroll>
            <div className="author">
              <div className="author__avatar">
                <PactImage
                  image={authorAvatar}
                  alt={`${author}${authorRole ? ` - ${authorRole}` : ''}`}
                />
              </div>
              <div className="author__info">
                <span className="author__name">{author}</span>
                {authorRole && (
                  <span className="author__role">{authorRole}</span>
                )}
              </div>
            </div>
          </ShowOnScroll>

        </React.Fragment>
      );
    });
  }, [data]);

  //#endregion
  //#region Handlers
  const onSlideChange = (swiper: Swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };


  //#endregion
  //#region Templating
  return (
    <StyledImageTestimonial>
      <Wrapper>
        <div className="holder">
          <SwiperJSX
            className="image"
            data-cursor-size="1"
            data-cursor-text="drag"
            data-cursor-color="var(--aqua)"
            onSlideChange={onSlideChange} 
            {...DEFAULT_OPTIONS}
          >
            {images.map((image, index) => {
              const imageSrc =
                image.childImageSharp.gatsbyImageData.images.fallback.src;
              const key = imageSrc
                ? `${imageSrc}-${index}`
                : `testimonial__image__${index}`;
              return (
                <SwiperSlide key={key}>
                  <ShowOnScroll>
                    <PactImage
                      image={image}
                      alt={data[currentIndex].testimonial}
                    />
                  </ShowOnScroll>
                </SwiperSlide>
              );
            })}
          </SwiperJSX>
          <div className="content">{testimonials[currentIndex]}</div>
        </div>
      </Wrapper>
    </StyledImageTestimonial>
  );
  //#endregion
};

export interface ImageTestimonialProps {
  data: {
    image: any;
    testimonial: string;
    author: string;
    authorRole: string;
    authorAvatar: any;
    clientLogo: any;
    clientName: string;
  }[];
}
