import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Section } from '../../styles/Section.style';
import { Wrapper } from '../../styles/Wrapper.style';
import { Cta } from '../Cta/Cta';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledCtaBanner } from './CtaBanner.style';

export const CtaBanner: React.FC<CtaBannerProps> = ({ data }) => {
  //#region Variables
  const { background, title, text, ctaLabel, ctaLink } = data;
  let cursorColor: string = 'var(--cursorColor)';

  if (background === 'aqua') {
    cursorColor = 'var(--pebble)';
  }
  //#endregion

  //#region Templating
  return (
    <StyledCtaBanner>
      <Wrapper>
        <ShowOnScroll>
          <Section className="box" bg={background} data-cursor-color={cursorColor}>
            <ShowOnScroll>
              <h2 className="title">{title}</h2>
            </ShowOnScroll>

            <ShowOnScroll delay={100}>
              <div className="content">
                <div className="text">
                  <ReactMarkdown>{text}</ReactMarkdown>
                </div>
                <Cta href={ctaLink}>{ctaLabel}</Cta>
              </div>
            </ShowOnScroll>
          </Section>
        </ShowOnScroll>
      </Wrapper>
    </StyledCtaBanner>
  )
  //#endregion
}

export interface CtaBannerProps {
  data: {
    background: ThemeBG;
    title: string;
    text: string;
    ctaLabel: string;
    ctaLink: string;
  }
}