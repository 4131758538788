import styled from "styled-components";
import { desktopHover, keepRatio, pxtorem, pxtovw } from "../../utils/Tools";
import { StyledPactImage } from "../PactImage/PactImage.styled";

const ratioMap: Record<string, string> = {
  wide: "1800/1320",
  normal: "878/1096",
};

export const StyledStoryItem = styled.div<StyledStoryItemProp>`
  --background: inherit;
  z-index: 2;


  .media {
    ${(p) => keepRatio(ratioMap[p.ratio])};

    ${StyledPactImage}, a {
      --bRadius: max(${pxtovw(80)}, 50px);

      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      ${(p) => p.roundedImage ? `border-radius: var(--bRadius) var(--bRadius) var(--bRadius) 0;` : ""};
      border-radius: ${(p) => (p.roundedImage ? "80px 80px 80px 0px;" : 0)};
    }

    a {
      transition: opacity 1s;
      ${desktopHover("opacity: 0.7;")}
    }

    ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }

    ${StyledPactImage} {
      overflow: hidden;
    }

    .gatsby-image-wrapper {
      transition: transform 2s cubic-bezier(0.22, 1, 0.36, 1);
    }

    ${desktopHover(`
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    `)}
  }

  .info {
    margin-top: 40px;
  }

  .category,
  .link {
    display: block;
    font: 400 16px/19px var(--secondaryFont);
    text-transform: uppercase;
  }

  .link {
    margin-top: 10px;
    transition: opacity 0.25s;
    opacity: 0.5;

    ${desktopHover("opacity: 1")};
  }

  .desc {
    font: 300 ${pxtorem(30)} / ${pxtorem(42)} var(--primaryFont);
    max-width: 750px;
    margin-top: 20px;
    p {
      margin: 0;
    }
  }
`;

interface StyledStoryItemProp {
  ratio: "wide" | "normal";
  customRatio?: string;
  roundedImage?: boolean;
}
