import React, { useContext, useRef, useState } from 'react';
import { StyledHomePage } from 'src/components/HomePage/HomePage.style';
import ShowOnScroll from 'src/components/ShowOnScroll/ShowOnScroll';
import { Wrapper } from 'src/styles/Wrapper.style';
import { Section } from '../../styles/Section.style';
import { formatDuration, getLocaleMdData } from '../../utils/Tools';
import { CaseStudyItemData } from '../CaseStudyItem/CaseStudyItem';
import { CtaBanner, CtaBannerProps } from '../CtaBanner/CtaBanner';
import { FancyText, FancyTextProp } from '../FancyText/FancyText';
import { HeroBanner, HeroBannerData } from '../HeroBanner/HeroBanner';
import { HomeCaseStudiesGrid } from '../HomeCaseStudiesGrid/HomeCaseStudiesGrid';
import { HomeStoriesCarousel } from '../HomeStoriesCarousel/HomeStoriesCarousel';
import { ImageTestimonial, ImageTestimonialProps } from '../ImageTestimonial/ImageTestimonial';
import { PactContext } from '../PactContext';
import { StoryItemData } from '../StoryItem/StoryItem';
import VolumeOnIcon from 'src/styles/svg/VolumeOn.svg'
import VolumeOffIcon from 'src/styles/svg/VolumeOff.svg'

export const HomePage: React.FC<HomePageProps> = ({ data }) => {
  const allStories: LocalizedFields<StoryItemData>[] = (data.allStories?.nodes || []) as LocalizedFields<StoryItemData>[];
  const { language } = useContext(PactContext);
  const [videoMuted, setVideoMuted] = useState(true);
  const [videoDuration, setVideoDuration] = useState('00:00');

  const _data = getLocaleMdData(data);
  const {
    heroBanner,
    caseStudies,
    caseStudiesGridCtaLabel,
    caseStudiesGridCtaUrl,
    fancyText,
    imageTestimonial,
    ctaBanner,
    stories,
    caseStudiesHeading,
    heroVideoUrl,
    heroVideoTitle
  } = _data[language] || {};

  const allCaseStudies: LocalizedFields<CaseStudyItemData>[] = (data.allCaseStudies?.nodes || []) as LocalizedFields<CaseStudyItemData>[];
  const filteredCaseStudies: CaseStudyItemData[] = allCaseStudies.filter(c => caseStudies.indexOf(c.fields.locales[language].title) > -1).map(c => c.fields.locales[language]);

  const handleMuteToggle = () => {
    setVideoMuted(prev => !prev);
  }

  const handlePlayStart = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const video = e.target as HTMLVideoElement;
    setVideoDuration(formatDuration(Math.ceil(video.duration)));
  }
  return (
    <StyledHomePage>
      <HeroBanner data={heroBanner[0]} />

      {heroVideoUrl && (
        <ShowOnScroll>
          <Wrapper width={1663}>
            <div className="video-controls">
              <video
                loop
                muted={videoMuted}
                playsInline
                autoPlay
                className="sizzle-reel"
                onPlay={handlePlayStart}
              >
                <source src={heroVideoUrl} type="video/mp4" />
              </video>
              <div className="video-info">
                <button onClick={handleMuteToggle}>
                  {!videoMuted ? <VolumeOnIcon /> : <VolumeOffIcon />}
                </button>
                <p>{heroVideoTitle}</p>
                <p>{videoDuration}</p>
              </div>
            </div>
          </Wrapper>
        </ShowOnScroll>
      )}

      <HomeCaseStudiesGrid
        caseStudies={filteredCaseStudies}
        order={caseStudies}
        ctaLabel={caseStudiesGridCtaLabel}
        ctaURL={caseStudiesGridCtaUrl}
        headingText={caseStudiesHeading}
      />

      <Section bg="pebble">
        <FancyText data={fancyText} />
        {imageTestimonial && imageTestimonial.length >= 1 && (
          <ImageTestimonial data={imageTestimonial} />
        )}

        {ctaBanner && ctaBanner.length >= 1 && (
          <CtaBanner data={ctaBanner[0]} />
        )}
      </Section>
      <HomeStoriesCarousel stories={allStories} title={stories[0].title} />
    </StyledHomePage>
  );
}

interface HomePageProps {
  data: LocalizedAllMarkdownDataRelation<HomePageData, CaseStudyItemData | StoryItemData>['data'];
}

interface HomePageData {
  pageTheme: ThemeBG;
  heroBanner: HeroBannerData[];
  caseStudies: string[];
  heroVideoUrl: string;
  heroVideoTitle: string;
  caseStudiesHeading: string;
  caseStudiesGridCtaLabel: string;
  caseStudiesGridCtaUrl: string;
  fancyText: FancyTextProp['data'];
  imageTestimonial: ImageTestimonialProps['data'];
  ctaBanner: CtaBannerProps['data'][];
  stories: {
    title: string;
  }[];
}
