import gsap from "gsap";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import Swiper, { SwiperOptions } from "swiper";
import { Swiper as SwiperJSX, SwiperSlide } from "swiper/react";
import { Wrapper } from "../../styles/Wrapper.style";
import { Clamp, gearRatio } from "../../utils/Tools";
import { PactContext } from "../PactContext";
import ShowOnScroll from "../ShowOnScroll/ShowOnScroll";
import { StoryItem, StoryItemData } from "../StoryItem/StoryItem";
import { StyledHomeStoriesCarousel } from "./HomeStoriesCarousel.style";

const DEFAULT_OPTIONS: SwiperOptions = {
  loop: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  slidesPerView: "auto",
  spaceBetween: 19,
};

const CAROUSEL_LABEL = {
  en: "Latest Stories",
  fr: "Derniers Articles",
} as const;

const MAX_STORIES: number = 10;

export const HomeStoriesCarousel: React.FC<HomeStoriesCarouselProps> = ({
  stories,
  title,
  ...props
}) => {
  const labelRef = useRef<HTMLParagraphElement>(null);
  const matchRef = useRef(typeof window === 'undefined' ? null : window.matchMedia('(max-width: 768px)'));
  
  const { language } = useContext(PactContext);

  const allStories = useMemo<StoryItemData[]>(() => {
    const allPublicStories = stories.filter(story => story.fields.locales[language].showInListing);
    const filteredByLang = allPublicStories.map(n => n.fields.locales[language]);

    return filteredByLang.sort((a,b) => {
      const aWeight = a.weight || 0;
      const bWeight = b.weight || 0;

      if (aWeight > bWeight) {
        return -1;
      } else if (aWeight < bWeight) {
        return 1;
      }

      return 0;
    }).slice(0, MAX_STORIES);
  }, []);

  const onSetTranslate = (swiper: Swiper) => {
    if (!labelRef.current || window.matchMedia('(max-width: 768px)').matches) return;

    const translateMax: number = 200;
    const clampedProgress: number = Clamp(swiper.translate, translateMax * -1, 0);
    const offset: number = 20;

    const xRatio = gearRatio(clampedProgress, translateMax * -1, 0, offset * -1, 0);
    const alphaRatio = gearRatio(clampedProgress, translateMax * -1, 0, 1, 0);

    gsap.set(labelRef.current, {
      x: xRatio,
      autoAlpha: 1 - alphaRatio,
    });
  };

  const onMatchMediaChange = (ev: MediaQueryListEvent) => {
    if (ev.matches && labelRef.current) {
      gsap.set(labelRef.current, { clearProps: 'all' });
    }
  }

  useEffect(() => {
    if (matchRef.current) {
      matchRef.current.addEventListener("change", onMatchMediaChange);
    }

    return () => {
      matchRef.current?.removeEventListener('change', onMatchMediaChange)
    }
  }, [matchRef.current]);

  return (
    <StyledHomeStoriesCarousel bg="charcoal">
        <ShowOnScroll>
          <Wrapper>
            <div className="header">
              <h2 className="title">{title}</h2>
            </div>
          </Wrapper>
        </ShowOnScroll>
        <div className="carousel-row">
          <div className="carousel-label">
            <ShowOnScroll>
              <p className="label" ref={labelRef}>{CAROUSEL_LABEL[language]}</p>
            </ShowOnScroll>
          </div>
          <SwiperJSX
            className="carousel"
            data-cursor-size="1"
            data-cursor-text="drag"
            data-cursor-color="var(--pebble)"
            onSetTranslate={onSetTranslate}
            {...{ ...DEFAULT_OPTIONS, ...props }}
          >
            {allStories.map((story) => {
              return (
                <SwiperSlide key={story.slug}>
                  <StoryItem item={story} key={story.slug} roundImages />
                </SwiperSlide>
              );
            })}
          </SwiperJSX>
        </div>
    </StyledHomeStoriesCarousel>
  );
};

interface HomeStoriesCarouselProps {
  stories: LocalizedFields<StoryItemData>[];
  title: string;
}
