import React, { useEffect, useMemo, useRef } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { CaseStudyItem, CaseStudyItemData } from '../CaseStudyItem/CaseStudyItem';
import { Cta } from '../Cta/Cta';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledHomeCaseStudiesGrid } from './HomeCaseStudiesGrid.style';

export const HomeCaseStudiesGrid: React.FC<CaseStudiesGridProps> = ({ caseStudies, ctaLabel, ctaURL, order, headingText }) => {
  //#region Hooks / Lifecycles
  const ratioMap = useRef<('wide' | 'tall' | 'square' | 'custom')[]>(['wide', 'wide', 'square', 'tall', 'wide']);

  const sortedStudies = useMemo<CaseStudyItemData[]>(() => {
    if (order && order.length >= 1) {
      return order.map(t => caseStudies.find(c => c.title === t));
    } else {
      return caseStudies.sort((a, b) => {
        const aWeight = a.weight || 0;
        const bWeight = b.weight || 0;

        if (aWeight > bWeight) {
          return -1;
        } else if (aWeight < bWeight) {
          return 1;
        }

        return 0;
      })
    }
  }, [])

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledHomeCaseStudiesGrid>
      {headingText && (
        <ShowOnScroll>
          <h2 className="heading">{headingText}</h2>
        </ShowOnScroll>
      )}
      <Wrapper width={1662}>
        <ul className="items">
          {sortedStudies.map((cs, i: number) => (
            <li className={`item item--${i + 1}`} key={`cs-grid-item-${i}`}>
              <CaseStudyItem item={cs} ratio={ratioMap.current[i]} />
            </li>
          ))}
        </ul>

        {ctaLabel && ctaURL && (
          <ShowOnScroll>
            <div className="ctaHolder">
              <Cta href={ctaURL}>{ctaLabel}</Cta>
            </div>
          </ShowOnScroll>
        )}
      </Wrapper>
    </StyledHomeCaseStudiesGrid>
  );
  //#endregion
}

interface CaseStudiesGridProps {
  ctaLabel: string;
  ctaURL: string;
  order?: string[];
  caseStudies: CaseStudyItemData[];
  headingText?: string;
}