import React from 'react';

export default function VolumeOffSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.333 8.33325L9.99967 14.9999H3.33301V24.9999H9.99967L18.333 31.6666V8.33325Z"
        fill="#FFFAF3"
        stroke="#FFFAF3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M38.333 15L28.333 25"
        stroke="#FFFAF3"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M28.333 15L38.333 25"
        stroke="#FFFAF3"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
