import React, { useRef } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledFancyText } from './FancyText.style';

export const FancyText: React.FC<FancyTextProp> = ({ data }) => {
  let imageIndex: number = 0;
  //#region Templating
  return (
    <StyledFancyText>
      <Wrapper width={1660}>
        <ShowOnScroll>
          {data.map((segment, i: number) => {
            if (segment.type === 'image') {
              const index: number = imageIndex;
              imageIndex++;
              const segmentData: FancyTextImage = segment as FancyTextImage;
              return (
                <div key={`fancy-${segment.type}-${i}`} className={`image ${segmentData.displayAsCircle ? 'image--circle' : ''}`}>
                  <ShowOnScroll passive delay={330 * index}>
                    <PactImage image={segmentData.image} alt="" />
                  </ShowOnScroll>
                </div>
              )
            } else {
              return `${i === 0 ? '' : ' '}${(segment as FancyTextWords).words} `
            }
          })}
        </ShowOnScroll>
      </Wrapper>
    </StyledFancyText>
  )
  //#endregion
}

export interface FancyTextProp {
  data: (FancyTextImage | FancyTextWords)[];
}

interface FancyTextElement {
  type: 'text' | 'image';
}

interface FancyTextImage extends FancyTextElement {
  image: any;
  displayAsCircle: boolean;
}

interface FancyTextWords extends FancyTextElement {
  words: string;
}