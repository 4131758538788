import React from 'react';

export default function VolumeOnSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.334 8.33325L10.0007 14.9999H3.33398V24.9999H10.0007L18.334 31.6666V8.33325Z"
        fill="#FFFAF3"
        stroke="#FFFAF3"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M31.7837 8.21655C34.9082 11.342 36.6635 15.5805 36.6635 19.9999C36.6635 24.4193 34.9082 28.6578 31.7837 31.7832M25.9004 14.0999C27.4626 15.6626 28.3403 17.7819 28.3403 19.9916C28.3403 22.2013 27.4626 24.3205 25.9004 25.8832"
        stroke="#FFFAF3"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
