import styled from "styled-components";
import { Section } from "../../styles/Section.style";
import { pxtorem, pxtovw } from "../../utils/Tools";

export const StyledHomeStoriesCarousel = styled(Section)`
  padding: ${pxtorem(160)} 0;
  position: relative;


  @media (max-width: 768px) {
    padding: 80px 0;
  }

  .header {
    margin-bottom: ${pxtovw(200)};

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }

    @media (min-width: 1920px) {
      margin-bottom: 200px;
    }
  }

  .title {
    font: 300 max(${pxtovw(80)}, 32px) / 107.5% var(--primaryFont);
    text-transform: uppercase;
    color: var(--pebble);
    text-indent: ${pxtovw(200)};

    @media (min-width: 1920px) {
      font: 400 120px/90% var(--primaryFont);
      text-indent: 200px;
    }
  }

  .carousel-row {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
    overflow: hidden;
    padding-left: var(--gutter);

    @media (min-width: 768px) {
      padding-left: 0;
      grid-template-columns: 140px 1fr;
      grid-template-rows: 1fr;
    }

    @media (min-width: 1200px) {
      grid-template-columns: 239px 1fr;
    }
  }

    .carousel {
      margin-inline: calc(var(--gutter) * -1);
      padding: 0 calc(var(--gutter) * 2) 0 var(--gutter);
    }

  .carousel-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 517px; 
    @media (min-width: 768px) {
      padding-left: 0;
    }
  }
  
  .label {
    white-space: nowrap;
    text-transform: uppercase;
    transform: rotate(-90deg);
    font: 400 16px / 90% var(--secondaryFont);
    @media (max-width: 768px) {
        font: 400 12px / 90% var(--secondaryFont);
        transform: rotate(0);
    }
  }

  .swiper-slide {
    max-width: 87.5vw;

    @media(min-width: 768px) {
      max-width: 439px;
    }
  }
`;
